import logo from './logo.png';
import './App.css';

import { useEffect, useState } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { loginRequest } from './authConfig';
import { jwtDecode } from "jwt-decode";

function App() {
	const { instance, accounts, inProgress } = useMsal();

	const queryParameters = new URLSearchParams(window.location.search);

	// const { accessToken } = queryParameters.get("name");
	// console.log(accessToken, queryParameters.get("accessToken"))

	// console.log(queryParameters);

	var accessToken = queryParameters.get("accessToken");
	var graphToken = queryParameters.get("graphAccessToken");

	console.log(accessToken, graphToken)

	// If there are accounts, that means a user is logged in
	//const isAuthenticated = accounts.length > 0;

	const isAuthenticated = useIsAuthenticated();

	const handleLogin = () => {
		instance.loginPopup(loginRequest)
		.then(function (loginResponse) {
			setOid(loginResponse.account.localAccountId);			
			console.log(loginResponse);
			setToken(loginResponse.accessToken);
		  })
		.catch(e => {
			console.error(e);
		});
	};

	const handleLogout = () => {
		instance.logoutPopup().catch(e => {
			console.error(e);
		});
	};

	const [ name, setName ] = useState();
	const [ userName, setUserName ] = useState();
	const [ oId, setOid ] = useState();
	const [ appId, setAppId ] = useState();
	const [ token, setToken ] = useState();
	const [ gtoken, setGToken ] = useState();

	// Get app id.
	function getAppId(appList) {
		var list = appList.value;
		var i;
		for (i = 0; i < list.length; i++) {
		if (list[i].teamsAppDefinition['displayName'] === "PersTabSSO") {
			return list[i].id;
		}
		}
	}

	function e7()
    {
		var lut = []; 
		for (var i=0; i<256; i++) { lut[i] = (i<16?'0':'')+(i).toString(16); }

    	var d0 = Math.random()*0xffffffff|0;
	    return lut[d0&0xff]+lut[d0>>8&0xff]+lut[d0>>16&0xff]+lut[d0>>24&0xff];
 	}

	const handleNotification3 = async () => {

		var headers = new Headers();
		var bearer = "Bearer " + token;
		headers.append("Authorization", bearer);
		headers.append("accept", 'application/json');
		headers.append("Content-Type", 'application/json');

		var url = "https://graph.microsoft.com/v1.0/me/activities/" + e7();

		var postData = {
			"activitySourceHost": "https://exruat.centralindia.cloudapp.azure.com",
			"activationUrl": "https://exruat.centralindia.cloudapp.azure.com/id=" + e7(),
			"id": e7(),
			"appActivityId": "/id=" + e7(),
			"status": "updated",
			"expirationDateTime": "2024-12-07T20:20:48.114Z",
			"visualElements": {
			  "displayText": "Contoso How-To: How to Tie a Reef Knot",
			  "description": "How to Tie a Reef Knot. A step-by-step visual guide to the art of nautical knot-tying.",
			  "attribution": {
				"iconUrl": "https://www.contoso.com/icon",
				"alternateText": "Contoso Ltd",
				"addImageQuery": false
			  },
			  "backgroundColor": "#ff0000",
			  "content": {
				"$schema": "https://adaptivecards.io/schemas/adaptive-card.json",
				"type": "AdaptiveCard",
				"body": [
				  {
					"type": "TextBlock",
					"text": "Contoso MainPage"
				  }
				]
			  }
			},
			"historyItems": [
			  {
				"userTimezone": "Africa/Casablanca",
				"startedDateTime": "2024-12-05T20:54:04.345Z",
				"lastActiveDateTime": "2024-12-05T20:54:24.345Z"
			  }
			]
		  }
		var options = {
			method: "PUT",
			headers: headers,
			body: JSON.stringify(postData)
		};

		fetch(url, options)
		.then(response => {console.log(response); response.json()})					
		.catch((error)=>{
			console.log(error)
		});

	};

	const handleNotification2 = async () => {

		if(!oId) { console.log(oId); return; }

		var headers = new Headers();
		var bearer = "Bearer " + token;
		headers.append("Authorization", bearer);
		headers.append("accept", 'application/json');
		headers.append("Content-Type", 'application/json');

		var url = "https://graph.microsoft.com/v1.0/users/" + oId + "/teamwork/installedApps/?$expand=teamsAppDefinition"

		var options = {
			method: "GET",
			headers: headers,
		};

		fetch(url, options)
		.then(response => response.json())
		.then(data => {
			var appId = data ? getAppId(data) : undefined;
			var testId= "ce15e788-af30-4d44-8456-1348753d6d3f";
			if(appId) {
				const postData = {
					"topic": {
					"source": "entityUrl",
					"value": "https://graph.microsoft.com/v1.0/users/" + testId + "/teamwork/installedApps/" + appId
					},
					"activityType": "taskCreated",
					"previewText": {
						"content": "New Task Created"
					},
					"templateParameters": [
					{
						"name": "actor",
						"value": 'Biju 1'
					},
					{
						"name": "taskName",
						"value": 'Test Task 1'
					}
					]
				};

				url = "https://graph.microsoft.com/v1.0/users/" + oId + "/teamwork/sendActivityNotification"
				var options = {
					method: "POST",
					headers: headers,
					body: JSON.stringify(postData)
				};
				fetch(url, options)
				.then(function (res) {
					console.log(res);
				});
			}
		})
		.catch((error)=>{
			console.log(error)
		});

	};
	
	const handleNotification1 = async () => {
		if(oId) {
			var headers = new Headers();
			var bearer = "Bearer " + token;
			headers.append("Authorization", bearer);
			headers.append("Content-Type", 'application/json');

			var testId= "ce15e788-af30-4d44-8456-1348753d6d3f";

			// var bodyContent = {
			// 	"topic": {
			// 		"source": "entityUrl",
			// 		"value": `https://graph.microsoft.com/v1.0/users/${oId}/teamwork/installedApps/{installationId}`
			// 	},
			// 	"activityType": "taskCreated",
			// 	"previewText": {
			// 		"content": "New Task Created"
			// 	},
			// 	"templateParameters": [
			// 		{
			// 			"name": "taskId",
			// 			"value": "Task 12322"
			// 		}
			// 	]
			// };
			var bodyContent = {
    "topic": {
        "source": "entityUrl",
        "value": "https://graph.microsoft.com/v1.0/appCatalogs/teamsApps/075da283-eb25-4d5e-8c74-8c6a865a60c6"
    },
    "activityType": "pendingFinanceApprovalRequests",
    "previewText": {
        "content": "Internal spending team has a pending finance approval requests"
    },
    "recipients": [
    	{
        	"@odata.type": "microsoft.graph.aadUserNotificationRecipient",
        	"userId": `${testId}`
    	},
    ],
    "templateParameters": [
        {
            "name": "pendingRequestCount",
            "value": "5"
        }
    ] 
}
			var options = {
				method: "POST",
				headers: headers,
				body: JSON.stringify(bodyContent)
			};
    		// var graphEndpoint = `https://graph.microsoft.com/v1.0/users/${testId}/teamwork/sendActivityNotification`;

			var graphEndpoint = "https://graph.microsoft.com/v1.0/teamwork/sendActivityNotificationToRecipients";

			fetch(graphEndpoint, options)
				.then(function (response) {
					console.log(response);
				});
		}
	};

	useEffect(() => {
		async function trySso() {
			const ssoRequest = {
				scopes: ["User.Read", "TeamsActivity.Send", "UserActivity.ReadWrite.CreatedByApp"],
				loginHint: userName,
			};
			
			instance.acquireTokenSilent(ssoRequest)
			.then(response => {
				// session silently established
			})
			.catch(error => {
				// handle error by invoking an interactive login method
				instance.acquireTokenPopup(ssoRequest);
			});
		  }

		if(inProgress === "none" && accessToken && graphToken && instance) {
			let deCodedAccessToken = jwtDecode(accessToken);
			console.log(deCodedAccessToken);
			setName(deCodedAccessToken.name);
			setUserName(deCodedAccessToken.preferred_username);
			setOid(deCodedAccessToken.oid);
			setAppId(deCodedAccessToken.aio);
			// setToken(accounts[0].idToken);
			setToken(accessToken);
			setGToken(graphToken);
			if(!isAuthenticated)
				trySso();			
		}
		
	  }, [accessToken, graphToken, inProgress, instance, isAuthenticated, setName, setUserName, userName]);

	return (
		<div className="App">
			<header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				{isAuthenticated ? (
					<div>
						<p>Name: {accounts[0].name}</p>
						<p>Email: {accounts[0].username}</p>
						<p className='para'>Token: {accounts[0].idToken}</p>
						<button onClick={handleLogout}>Logout</button> <br />
						<button onClick={handleNotification1}>Call sendActivityNotificationToRecipients</button>
						<button onClick={handleNotification2}>Call sendActivityNotification installedApps</button>
						<button onClick={handleNotification3}>Call Me Activities</button> <br />
						<p className='para'>GToken: {gtoken}</p>
					</div>
				) : accessToken ? (
					<div>
					<button onClick={handleLogin}>Login</button> <br />
					<p className='para'>Name: {name}</p>
					<p className='para'>Email: {userName}</p>
					</div>
				)
				: (
					<div>
					<button onClick={handleLogin}>Login</button> <br />
					</div>
				)}
			</header>
		</div>
	);
}

export default App;
