import { BrowserCacheLocation } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: '643b9ae1-8c6f-4437-9f8e-47eb61352f01',
        authority: 'https://login.microsoftonline.com/c2f4c3c5-5c79-47c8-a8e4-704a5d06a036',
        redirectUri: 'https://exruat.centralindia.cloudapp.azure.com',
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, //'sessionStorage',
        storeAuthStateInCookie: false,
    },
};

export const loginRequest = {
    scopes: ['User.Read', 'TeamsActivity.Send', 'UserActivity.ReadWrite.CreatedByApp'],
};

//redirectUri: 'https://exruat.centralindia.cloudapp.azure.com',